.main {
  padding: 0 144px 0 120px;
}
.mainTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  margin-bottom: 24px;
}
.blockInfo {
  padding: 16px 12px;
  background-color: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 24px;
}
.footerContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  padding: 10px 0;
  background-color: #ffffff;
}
