.container {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.container:hover {
  background-color: #f2f6f7;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
.topRow {
  display: flex;
  justify-content: space-between;
}
.topRowLabel {
  width: 80%;
  color: #91a4ab;
}

.middleRowTopText {
  color: rgba(0, 32, 51, 0.6);
  margin-bottom: 4px;
}
.middleRowBottomText {
  font-size: 12px;
  margin-bottom: 12px;
  color: #91a4ab;
}

.middleRowInRow {
  display: flex;
  gap: 12px;
  justify-content: stretch;
  align-items: stretch;
}
