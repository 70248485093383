.inputGroupContainer {
  position: relative;
  display: flex;
  gap: 24px;
  margin-bottom: 8px;
}

.label {
  padding-top: 10px;
  text-align: right;
  min-width: 258px;
  color: #91a4ab;
}
.children {
  width: 100%;
}

.labelRight {
  position: absolute;
  top: 10px;
  left: 890px;
  min-width: 258px;
  font-size: 12px;
  line-height: 150%;
  color: #91a4ab;
}
