.blockInfoContainer {
  padding: 16px 0 24px 24px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
}
.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 24px;
}
.textContainer {
  display: flex;
  gap: 50px;
  margin-bottom: 28px;
  width: 100%;
}
.textContainer:last-child {
  margin-bottom: 0;
}
.textLeft {
  width: 200px;
  color: #91a4ab;
}
.textRightColor {
  color: #0076a3;
}

.flexPhotoContainer {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
}
