.main {
  max-width: 870px;
  margin-left: 120px;
}

.flexContainerHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
}

.avatarContainer {
  position: relative;
  width: 136px;
  height: 136px;
  border: 1px solid #c7d9e0;
  border-radius: 4px;
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.iconDeleteAvatar {
  position: absolute;
  right: 150px;
  bottom: 0;
  padding: 6px;
  cursor: pointer;
  border: 2px solid #91a4ab;
  border-radius: 5px;
}

.blockInfoContainer {
  margin-bottom: 48px;
}

.title {
  padding-left: 282px;
  margin-bottom: 24px;
}

.socialNetwork div:not(:last-child) {
  margin-bottom: 8px;
}

.socialNetworkContainer {
  position: relative;
}

.iconDelete {
  position: absolute;
  left: 615px;
  padding: 6px;
  cursor: pointer;
  border: 2px solid #91a4ab;
  border-radius: 5px;
}
.footerContainer {
  width: 100%;
  background-color: #ffffff;
  padding: 16px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.footerActionsButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footerInformer {
  flex-grow: 2;
}
