.wrapper {
  display: flex;
  justify-content: center;
}
.container {
  width: 50%;
}
.percentProgress {
  display: flex;
  justify-content: center;
  gap: 20px;
}
