.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0px 120px;
  border-bottom: 1px solid #c7d9e0;
}
.tabsContainer {
  display: flex;
  gap: 16px;
}
.active {
  border-bottom: 2px solid #0076a3;
}
.link {
  color: #91a4ab;
}
.userContainer {
  position: relative;
  cursor: pointer;
}
.user {
  user-select: none;
}
.userMenu {
  position: absolute;
  top: 50px;
  left: 30px;
  z-index: 100;
  min-width: 170px;
  background-color: white;
  border: 1px solid rgba(0, 65, 102, 0.2);
  box-shadow:
    0px 4px 4px rgba(0, 32, 51, 0.04),
    0px 8px 24px rgba(0, 32, 51, 0.12);
  border-radius: 4px;
}
.userMenu div {
  user-select: none;
  padding: 15px 10px;
  transition: background-color 0.4s ease;
}
.userMenu div:hover {
  background-color: #e6eef1;
}
