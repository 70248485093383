.blockInfoContainer {
  break-inside: avoid;
  overflow: hidden;
  padding: 16px 24px 0px 24px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
}
.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 24px;
}
.textContainer {
  display: flex;
  margin-bottom: 24px;
}
.textLeft {
  min-width: 50%;
  color: #91a4ab;
}
.noInformation {
  color: #91a4ab;
}
.linkContainer {
  margin-bottom: 5px;
}
.link {
  color: #0076a3;
}
.textRight {
  word-break: break-word;
}
