.wrapper {
  padding: 0px 120px;
  height: 100%;
}
.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}
.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
}

.buttonIconFlex {
  display: flex;
  align-items: center;
  gap: 24px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.autoCompleteContainer {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
}
.approved {
  color: #35af0c;
}
.uploaded {
  color: #0076a3;
}
