.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.authFooterContainer {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.loginFormContainer {
  width: 440px;
  padding: 40px 60px 48px 60px;
  background-color: #f2f6f7;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 40px;
}
.labelTextFild {
  font-weight: 400;
  font-size: 12px;
  color: #91a4ab;
  padding-left: 8px;
  margin-bottom: 24px;
}
.labelText {
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 14px;
  padding: 12px;
  color: #91a4ab;
  background-color: #e6eef1;
  border-radius: 4px;
}
.supportContainer {
  display: flex;
  gap: 16px;
}
.support {
  font-weight: 400;
  font-size: 16px;
  color: #00678e;
}
