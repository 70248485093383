.alertCard {
  width: 450px;
  height: 170px;
  display: flex;
  gap: 20px;
  padding: 20px;
  background-color: #fff;
}
@media (max-width: 1600px) {
  .alertCard {
    width: 350px;
    height: auto;
  }
}
.mainAlert {
  display: flex;
  flex-direction: column;
}
.alertTitle {
  font-weight: 600;
  margin-bottom: 13px;
}
.alertText {
  color: #91a4ab;
  margin-bottom: 13px;
}
.alertButton {
  margin-top: auto;
}
