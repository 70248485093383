.wrapper {
  padding: 0 120px;
  margin-bottom: 24px;
}
.flexContainer {
  display: flex;
  align-items: baseline;
  gap: 24px;
}
.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}
