.wrapper {
  padding: 0px 120px 56px 120px;
}

.flexContainerHeader {
  display: flex;
  gap: 24px;
  margin-bottom: 32px;
}

.avatarContainer {
  min-width: 136px;
  height: 136px;
  border: 1px solid #c7d9e0;
  border-radius: 4px;
}
.avatarConsta {
  min-width: 136px;
  height: 136px;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 100%;
  position: relative;
}

.fullName {
  width: 314px;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 24px;
  line-height: 150%;
}
.buttonContainerLeft {
  display: flex;
  gap: 16px;
}

.buttonContainerRight {
  display: flex;
  gap: 24px;
  margin-top: 96px;
}

.blockInfo {
  column-count: 2;
  column-gap: 20px;
}
.blockInfoContainer {
  break-inside: avoid;
  padding: 16px 24px 0px 24px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
}
.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 24px;
}
.textContainer {
  display: flex;
  padding-bottom: 28px;
}
.textLeft {
  min-width: 50%;
  color: #91a4ab;
}
