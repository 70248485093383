.wrapper {
  padding: 0px 120px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
}
.buttonIconFlex {
  display: flex;
  align-items: center;
  gap: 24px;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.autoCompleteContainer {
  display: flex;
  gap: 15px;
  margin-bottom: 24px;
}
.newStatus {
  color: #91a4ab;
}
.activeStatus {
  color: #35af0c;
}
.blockedStatus {
  color: #df2a55;
}
.pagination{
  padding: 20px 0;
}