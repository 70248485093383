@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: inherit;
}

html,
body {
  font-family: 'Inter', sans-serif;
  height: 100%;
}

ul,
ol {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}
