.wrapper {
  padding: 0px 120px;
  height: 100%;
}
.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  margin-bottom: 24px;
}
.statsCardsContainer {
  display: flex;
  justify-content: space-between;
  gap: 3%;
  margin-bottom: 20px;
}
.statsCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  background-color: #fff;
}

.alertCardsContainer {
  display: flex;
  gap: 3%;
  margin-bottom: 24px;
}
.digitStatsTitle {
  margin-bottom: 15px;
}
.digitContainer {
  display: flex;
  gap: 10px;
}
.digit {
  font-size: 48px;
  font-weight: 700;
}
.percent {
  font-size: 34px;
  color: #35af0c;
  align-self: flex-end;
}
.negativePercent {
  font-size: 34px;
  color: #df2a55;
  align-self: flex-end;
}
.numberDays {
  font-size: 48px;
  font-weight: 700;
  color: #0076a3;
}
.criteriaCard {
  width: 100%;
  padding: 20px;
  background-color: #fff;
}
.criteriaStatsTitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}
.badgeContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
