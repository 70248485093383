.fieldsGroup {
  position: relative;
  border-bottom: 1px solid #c7d9e0;
  margin-bottom: 16px;
  padding-bottom: 6px;
}

.fieldsGroupMainInfo {
  position: relative;
  padding-bottom: 6px;
}

.cart {
  position: absolute;
  bottom: 14px;
  left: 890px;
  padding: 6px;
  cursor: pointer;
  border: 2px solid #91a4ab;
  border-radius: 5px;
}
