.blockInfoContainer {
  padding: 16px 15% 24px 24px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
}
.title {
  display: flex;
  gap: 30px;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 24px;
}
.textContainer {
  display: flex;
  gap: 50px;
  margin-bottom: 28px;
  width: 100%;
}
.textContainer:last-child {
  margin-bottom: 0;
}
.textLeft {
  min-width: 200px;
  color: #91a4ab;
}
.textRight {
  position: relative;
}
.iconCopy {
  position: absolute;
  top: 0px;
  right: -30px;
  cursor: pointer;
}
.flexPhotoContainer {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
}
.photoContainer {
  position: relative;
  width: 134px;
  height: 134px;
  border: 1px solid #c7d9e0;
  border-radius: 4px;
}
.download {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 6px 6px 3px 6px;
  cursor: pointer;
  background-color: rgba(48, 63, 68, 0.2);
  border-radius: 4px;
  color: #ffffff;
}
.photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.flexAttachment {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
}
.flexTag {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
