.wrapper {
  padding: 0 120px;
  height: 100%;
}

.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 24px;
}

.headerContainer .headerButton:not(:last-child) {
  margin-right: 16px;
}

.filtrationContainer {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 24px;
}
