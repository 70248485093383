.main {
  max-width: 870px;
  margin-left: 120px;
}
.mainTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  margin-bottom: 24px;
}

.footerContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  padding: 10px 0px 10px 0px;
  background-color: #ffffff;
}
.fieldIndentation {
  margin-bottom: 24px;
}
.dragNDrop {
  background-color: #ffffff;
}
.dragNDrop:hover {
  background-color: #ffffff;
}

.imageContainer {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 24px;
}

.attachmentContainer {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 20px;
}

.avatarContainer {
  position: relative;
  width: 136px;
  height: 136px;
  border: 1px solid #c7d9e0;
  border-radius: 4px;
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.iconTrash {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 6px;
  cursor: pointer;
  background-color: rgba(48, 63, 68, 0.2);
  border-radius: 4px;
  color: #ffffff;
}

.fieldDatePicker {
  width: 100%;
  margin-bottom: 24px;
}
.checkboxGroup {
  display: grid;
  gap: 28px;
  grid-template-columns: repeat(2, 1fr);
}
.textTop {
  color: rgba(0, 32, 51, 0.6);
  margin-bottom: 4px;
}
.textBottom {
  font-size: 12px;
  margin-bottom: 12px;
  color: #91a4ab;
}
