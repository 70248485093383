.modal {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: fixed;
  z-index: 1;
  top: 60px;
  right: 0;
  padding: 24px;
  width: 386px;
  height: calc(100vh - 60px);
  background-color: #f2f6f7;
  box-shadow: 0px 0px 30px rgba(48, 63, 68, 0.3);
}
.dropdown {
  z-index: 100;
}
.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  margin-bottom: 24px;
}
.buttonContainer {
  display: flex;
  justify-content: space-between;
}
