.modal {
  position: absolute;
  top: 40px;
  left: 110px;
  padding: 24px;
  width: 353px;
  background-color: #f2f6f7;
  box-shadow: 0px 0px 30px rgba(48, 63, 68, 0.3);
}

.text {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 24px;
}

.buttonFlexContainer {
  display: flex;
  gap: 16px;
  max-width: 350px;
}

.buttonCancel {
  width: 130px;
}

.buttonUnlock {
  background-color: #35af0c;
}
