.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 40px;
}

.supportHeaderContainer {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.supportFormContainer {
  width: 720px;
  padding: 40px 60px 0px 60px;
  background-color: #f2f6f7;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flexContainer {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
  width: 100%;
}
.text {
  font-style: normal;
  font-weight: 400;
  color: #303f44;
  width: 304px;
  margin-bottom: 40px;
  text-align: center;
}
